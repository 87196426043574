$colors: (
  primary: #345FDB,
  secondary: #2e4057,
  danger: #e7253c,
  success: #4cbe1e,
  warning: #dd621b
);

$gradients: (
  primary:
  linear-gradient(
      to right,
      map-get($colors, 'primary'),
      #347CDB
  ),
  danger:
  linear-gradient(
      to right,
      map-get($colors, 'danger'),
      #E74C3C
  ),
  secondary:
  linear-gradient(
      to right,
      map-get($colors, 'secondary'),
      #394F6B
  ),
  success:
  linear-gradient(
      to right,
      map-get($colors, 'success'),
      #51CB20
  ),
  warning:
  linear-gradient(
      to right,
      map-get($colors, 'warning'),
      #DD7B1B
  )
);

$brand-primary: map_get($colors, "primary");
$brand-danger: map_get($colors, "danger");
$brand-success: map_get($colors, "success");
$brand-secondary: map_get($colors, "secondary");
$brand-warning: map_get($colors, "warning");

$flag-bronze-color: #863d25;
$flag-silver-color: #4d7691;
$flag-gold-color: #d89926;
$flag-platinum-color: #50b3a4;
$flag-diamond-color: #3498DB;

$primary-gradient: linear-gradient(to right, $brand-primary, #347CDB);
$primary-gradient-reverse: linear-gradient(to left, $brand-primary, #347CDB);
$danger-gradient: linear-gradient(to right, #E74C3C, $brand-danger);
