.device {
  position: relative;
}

.device-screen {
  position: relative; //box-shadow: 0 0 0 14px rg,0 0 0 15px #121920;
  border-top-left-radius: .1px;
  border-top-right-radius: .1px;
  box-shadow: 0 0 0 15px #111, 0 0 0 16px rgba(52, 96, 217, .5), 0 0 0 19px #111, 0 0 0 20px rgba(52, 96, 217, .5), 0 0 26px 20px rgba(0, 0, 0, .3);
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive-4by8::before {
  padding-top: 105%;
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.alert {
  display: block;
  padding: 0.75rem;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: .9rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;

  &.alert-danger {
    border-color: transparentize($brand-danger, .5);
    background-color: transparentize($brand-danger, .9);
    color: $brand-danger;
  }

  &.alert-success {
    border-color: transparentize($brand-success, .5);
    background-color: transparentize($brand-success, .9);
    color: darken($brand-success, 15);
  }
}
