.modal-header {
  padding: 1.5rem;
  border-bottom: 1px solid #e9ecef;
  background: #131d31;
  color: #fff;

  .modal-title {
    line-height: 1.5rem;
    font-size: 1.25rem;
  }

  .close {
    background: transparent;
    border: 0;
    cursor: pointer;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }

  .back {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    margin: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    cursor: pointer;
  }
}

.modal-body {
  padding: 1.5rem;
  background: linear-gradient(#20242d, #0b0c0e);
  color: #fff;

  .badge-img{
    height: 120px;
    cursor:pointer;
  }
}

.modal-p{
  padding-bottom: 1rem;
}

.line{
  border-bottom: 1px solid #e9ecef;
}

.close-button{
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

