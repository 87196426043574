@import "variables";

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
  font-family: "poppins", sans-serif;
}

h1 {
  font-size: 2.5rem;
  line-height: 3rem;
}

h2 {
  font-size: 2rem;
  line-height: 3rem;
}

h3 {
  font-size: 1.75rem;
  line-height: 3rem;
}

h4 {
  font-size: 1.5rem;
  line-height: 3rem;
}

h5 {
  font-size: 1.25rem;
  line-height: 3rem;
}

h6 {
  font-size: 1rem;
  line-height: 1.5rem;
}

p {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

a, a:visited, a:active, a:hover, a:focus {
  color: inherit;
  text-transform: none;
  text-decoration: none;
}

.text-mon {
  font-family: 'poppins', sans-serif !important;
}

.text-bj {
  font-family: 'big_johnregular', sans-serif !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-primary {
  color: map-get($colors, 'primary');
}

.text-danger {
  color: map-get($colors, 'danger');
}

.text-justify {
  text-align: justify;
}

.font-weight-bold {
  font-weight: 600;
}

.font-weight-semibold {
  font-weight: 500;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: 0;
  border-top: 1px solid #eeeeee;
}
