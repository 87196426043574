$base-font-size: 16px;
$base-line-height: 24px;

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@import "normalize-scss/sass/normalize";
@include normalize();

//@import "~flexboxgrid/css/flexboxgrid.min.css";
//@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "variables";
@import "typography";
@import "extras";
@import "helpers";
@import "forms";
@import "modals";

@import "@angular/cdk/overlay-prebuilt.css";

* {
  box-sizing: border-box;
}

body {
  font-family: "poppins", sans-serif;
  line-height: 1.5;
  overflow-x: hidden !important;
  font-weight: 400;
  color: #212529;
}

.container {
  max-width: 1340px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

img.ng-lazyloaded {
  animation: fadein .5s;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@media (max-width: 650px) {
  html, body {
    font-size: 14px;
  }
}
