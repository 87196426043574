.--d-block, .d-block {
  display: block;
}

.--d-inline-block, .d-inline-block {
  display: inline-block;
}

.--d-flex {
  display: flex;
}

.--mb-0 {
  margin-bottom: 0;
}
