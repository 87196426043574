label {
  display: inline-block;
  font-size: .9rem;
  line-height: 2.35rem;
}

input, textarea, select {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  transition: all .3s;
  font-size: .9rem;
  display: block;
  padding: .5rem;
  width: 100%;

  &:hover {
    border-color: #40a9ff;
  }

  &:focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, .2);
  }
}

input {
  height: 2.25rem;
}

.form-group {
  margin-bottom: .75rem;
  position: relative;

  input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    opacity: 0;

    & ~ label {
      display: flex;
      align-items: center;

      &:before {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        transition: border-color .3s, background-color .3s;
        border: 1px solid transparent;
        border-radius: 3px;
        margin-right: .5rem;
      }

      &:before {
        border: 1px solid #d9d9d9;
      }

      &:hover:before {
        border-color: #40a9ff !important;
      }
    }

    &:checked ~ label:before {
      border-color: $brand-primary !important;
      background: $brand-primary no-repeat 50%/50% 50%;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
  }
}

.switch {
  display: inline-block;
  vertical-align: middle;
  min-width: 40px;
  width: 40px;
  height: 20px;
  margin: 0 8px;
  position: relative;
  input {
    display: none;
  }
  input:checked+.slider:before {
    transform: translateX(20px);
  }
  input:checked+.slider {
    background: limeGreen;
  }
  input:disabled:checked+.slider {
    background: limeGreen;
  }
  input:checked:disabled+.slider {
    background: limeGreen;
  }

  &.red {
    input:checked+.slider:before {
      transform: translateX(20px);
      background: #cd3232;
    }
    input:checked+.slider {
      box-shadow: 0 0 0 2px #cd3232, 0 0 2px #cd3232;
    }
  }

  .slider {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 30px;
    background: #b4b4b4;
    // background: #1f2838;
    cursor: pointer;
    border: 4px solid transparent;
    overflow: hidden;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 30px;
      transform: translateX(-20px);
      transition: .4s;
    }
  }
}
